import React, { useContext } from 'react'
import ClassicLayout from '../components/Layouts/ClassicLayout'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const UI = ({ data, lang }) => {
  const { language, defaultLanguage } = useContext(I18nextContext)

  return (
    <ClassicLayout>
      <div>
        <h1>h1 -- What works, no more, no less.</h1>
        <h2>h2 -- Maîtriser la complexité, dompter le chaos</h2>
        <h3>h3 -- Our obsessions are what bind us together.</h3>
        <h4>h4 -- Hire us</h4>
        <h5>
          h5 -- We lean on our clients expertise and assets to create value and generate new business opportunities
        </h5>
        <p>Default text - Design and technology shape the future of every industry.</p>
        <p className="ft-primary">.ft-primary - hello@source.paris</p>
        <p className="ft-secondary">
          .ft-secondary – Source is a group of Designer & technologists on a mission to make Corporate and Startups more
          capable through state of the art digital solutions.
        </p>
        <p className="ft-tertiary">.ft-tertiary - Tech</p>

        <button className="btn">More infos</button>
        <button className="btn btn--big btn--border-gray">Hire us</button>
        <div className="bg-gray-400">
          <p>
            Culpa sint do exercitation fugiat officia mollit culpa adipisicing. Nisi eu sint ex ut aliquip mollit. Est
            est reprehenderit ea cupidatat mollit proident enim do non adipisicing esse.
          </p>
          <p>
            Culpa sint do exercitation fugiat officia mollit culpa adipisicing. Nisi eu sint ex ut aliquip mollit. Est
            est reprehenderit ea cupidatat mollit proident enim do non adipisicing esse.
          </p>
          <p>
            Culpa sint do exercitation fugiat officia mollit culpa adipisicing. Nisi eu sint ex ut aliquip mollit. Est
            est reprehenderit ea cupidatat mollit proident enim do non adipisicing esse.
          </p>
        </div>

        <div>Current locale: {language}</div>
        <div>Current defaultLang: {defaultLanguage}</div>
      </div>
    </ClassicLayout>
  )
}

export default UI
